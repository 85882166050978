<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ city.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.cities.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in russian" placeholder="Please enter a name in russian" outlined :rules="nameRules" required v-model="city.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in english" placeholder="Please enter a name in english" outlined :rules="nameRules" required v-model="city.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in armenian" placeholder="Please enter a name in armenian" outlined :rules="nameRules" required v-model="city.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Slug" placeholder="Please enter a slug (english only)" outlined v-model="city.slug" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in armenian"  label="Description in armenian"
                                           v-model="city.description_hy" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field  placeholder="Please enter a description in russian" label="Description in russian"
                                           v-model="city.description_ru" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field   placeholder="Please enter a description in english" label="Description in english"
                                            v-model="city.description_en" outlined ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-center align-center flex-column">
                            <div class="min-width100">
                                <avatar-edit min-width="100%"
                                             :current_url="city.image_url"
                                             @input="handleSaveCity"
                                             v-model="city.image_url"
                                ></avatar-edit>
                            </div>
                            <div class="min-width100">
                                <v-text-field label="Image URL" v-model="city.image_url" disabled></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-if="city.created_at">
                        <v-col>
                            <v-text-field label="Created at" v-model="city.created_at" disabled ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveCity">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import City from "../../../models/City";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import AvatarEdit from "../../../components/AvatarEdit";

export default {
    name: "cities-show",



    components: {AdminDashboardLayout, AvatarEdit},
    data: function () {
        return {
            formatDate: 0,
            flagSaveUpdate: false,
            city: {},
            formValid: true,
            nameRules: [v => !!v || 'Name is required'],
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSaveCity() {
            this.formValid = this.$refs.form.validate()
            if (this.formValid) {
                const city = await new City(this.city);
                this.city = this.flagSaveUpdate ? await city.patch() : await city.save()
                await this.$router.push({name: 'admin.cities.show', params: {id: this.city.id}}).catch(()=>{})
                this.snackbar = true
            }
        },
        returnFormDate(uc) {
            this.formatDate = new Date(uc).toLocaleString()
        }
    },
    async mounted() {
        const cityId = this.$route.params.id
        if (cityId) {
            this.city = await City.find(cityId)
            this.flagSaveUpdate = true
            this.returnFormDate(this.city.created_at)
        }
    },
}
</script>

<style scoped>

</style>
